import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import "../styles/global.css";

const NotFoundPage = () => {
  return (
    <Layout>
      <main
        style={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 20,
          textAlign: "center",
        }}
      >
        <h1 style={{ marginTop: 120 }}>Page not found</h1>
        <p>
          Sorry{" "}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{" "}
          we couldn’t find what you were looking for.
          <br />
          <div style={{ maxWidth: 140, margin: "auto", marginTop: 20 }}>
            <Link to="/">
              <button>Go home</button>
            </Link>
          </div>
        </p>
      </main>
    </Layout>
  );
};

export default NotFoundPage;
